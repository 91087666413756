// clearfix
.clearfix:after,
.form-wrapper:after,
.form-row:after,
.form-action:after,
.cms:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}

* html .clearfix,
* html .form-wrapper,
* html .form-row,
* html .form-action,
* html .cms {
  height: 1%;
}

/* -- The following code is taken from GTM, when moving general fixes to the codebase -- */
.image-button {
  position: relative;
  overflow: hidden;
}

.image-button img {
  max-width: 100%;
  transition: all 0.3s;
}

.image-button:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 670px) {
  [class*='image-container'] {
    width: 100% !important;
    display: block !important;
    float: none !important;
    padding: 0 0 20px 0 !important;
  }

  [class*='personal-image'] {
    width: 50% !important;
  }

  [class*='text-container'] {
    width: 100% !important;
    display: block !important;
    float: none !important;
  }
}
