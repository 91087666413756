@import "version";

// colours
$colorBase: #333;
$colorBlack: #000;
$colorWhite: #fff;
$colorOffWhite: #eee;
$colorRed: red;
$colorGreen: yellowgreen;
$colorYellow: yellow;

// links
$colorLink: red;
$colorLinkHover: blue;

// 50 shades
$colorGrey: #888;
$colorGreyDark: darken($colorGrey, 10%);
$colorGreyLight: lighten($colorGrey, 10%);
$colorBorder: #d6d6d6;
$colorDivider: #ededed;

// social colours
$colorSocialFacebook: #3b5998;
$colorSocialTwitter: #00aced;
$colorSocialGooglePlus: #dd4b39;
$colorSocialLinkedIn: #007bb6;
$colorSocialYouTube: #bb0000;
$colorSocialInstagram: #517fa4;
$colorSocialPinterest: #cb2027;
$colorSocialFlickr: #ff0084;
$colorSocialTumblr: #32506d;
$colorSocialVine: #00bf8f;
$colorSocialRss: #f26522;

// responsive breakpoints
$maxPageWidth: 1200px;
$bpMobileLarge: 500px; // for 480px
$bpTablet: 620px; // for 600px
$bpTabletLarge: 788px; // for 768px
$bpDesktop: 980px; // for 960px;
$bpLarge: 1420px;
$bpExLarge: 1949px;

// mixins
@mixin bg($url, $repeat: no-repeat, $position: 0 0, $other: null) {
	background: url(#{$url}?v=#{$cssversion}) $repeat $position $other;
}

@mixin min-height($a: 5px) {
	min-height: $a;
	height: auto !important;
	height: $a;
}

@mixin filter($a) {
	filter: $a;
}

@mixin opacity($a: .8) {
	opacity: $a;
}

@mixin gradient-vertical($b: #eee, $a: #fff) {
	background-image: linear-gradient(top, $b, $a);
}

@mixin gradient-horizontal($b: #eee, $a: #fff) {
	background-image: linear-gradient(left, $b, $a);
}

@mixin transition($a...) {
	transition: $a;
}

@mixin transform-origin($a) {
	transform-origin: $a;
}

@mixin animation ($a) {
	animation: $a;
}

@mixin animation-pause () {
	animation-play-state: paused;
}

@mixin background-size($a: 100% auto) {
	background-size: $a;
}

@mixin background-clip($a: padding-box) {
	background-clip: $a;
}

@mixin box-shadow($a: 1px 1px 2px 0 rgba(0, 0, 0, .08)) {
	box-shadow: $a;
}

@mixin box-sizing($a: border-box) {
	box-sizing: $a;
}

@mixin border-radius($a: 2px) {
	border-radius: $a;
}

@mixin border-radius-adv($tl: 2px, $tr: 2px, $bl: 2px, $br: 2px) {
	border-top-right-radius: $tr;
	border-bottom-right-radius: $br;
	border-bottom-left-radius: $bl;
	border-top-left-radius: $tl;
	@include background-clip();
}

@mixin text-shadow($a: 1px 1px 1px rgba(0, 0, 0, .2)) {
	text-shadow: $a;
}

@mixin text-overflow($a: ellipsis) {
	overflow: hidden;
	-ms-text-overflow: $a;
	text-overflow: $a;
	white-space: nowrap;
}

@mixin text-hidden() {
	overflow: hidden;
	text-indent: -9999px;
}

@mixin remove-text-hidden() {
	overflow: visible;
	text-indent: 0;
}

@mixin text-size-adjust($a: 100%) {
	text-size-adjust: 100%;
}

@mixin placeholder-text($a: #333, $b: rgba(51, 51, 51, 1)) {
	&:-moz-placeholder {
		color: $a;
		color: $b;
		opacity: 1;
	}

	&::-moz-placeholder {
		color: $a;
		color: $b;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $a;
		color: $b;
		opacity: 1;
	}

	&::-webkit-input-placeholder {
		color: $a;
		color: $b;
		opacity: 1;
	}
}

@mixin transform($a) {
	transform: $a;
}

@mixin rotate($a: -5deg) {
	@include transform(rotate($a));
}

@mixin user-select($a: none) {
	user-select: $a;
}

@mixin object-fit($a: cover) {
	object-fit: $a;
}

@mixin arrow($a: 8px) {
	border: 0 solid transparent;
	content: "";
	display: block;
	height: 0;
	margin: 0;
	padding: 0;
	width: 0;
}

@mixin arrow-up($a: 8px, $b: $colorWhite) {
	@include arrow($a);
	border-width: $a - 2 $a / 2;
	border-top: 0;
	border-bottom-color: $b;
}

@mixin arrow-down($a: 8px, $b: $colorWhite) {
	@include arrow($a);
	border-width: $a - 2 $a / 2;
	border-bottom: 0;
	border-top-color: $b;
}

@mixin arrow-left($a: 8px, $b: $colorWhite) {
	@include arrow($a);
	border-width: $a / 2 $a - 2;
	border-left: 0;
	border-right-color: $b;
}

@mixin arrow-right($a: 8px, $b: $colorWhite) {
	@include arrow($a);
	border-width: $a / 2 $a - 2;
	border-right: 0;
	border-left-color: $b;
}

// media queries
$mq: false !default;

@mixin mq-min($a) {
	@if $mq {
		@if $mq >=$a {
			@content;
		}
	}

	@else {
		@media screen and (min-width: $a) {
			@content;
		}
	}
}

@mixin mq-max($a) {
	@if $mq {
		@if $mq <=$a {
			@content;
		}
	}

	@else {
		@media screen and (max-width: $a) {
			@content;
		}
	}
}

@mixin mq-min-max($min, $max) {
	@if $mq {
		@if $mq >=$min and $mq <=$max {
			@content;
		}
	}

	@else {
		@media screen and (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
}
