@import "config";
@import "base/print";

html,
body {
	font-family: $fontSimple;
}


// cms
.cms {
	blockquote {
		font-family: $fontSerif;
		font-size: $fontSize + 10;
		line-height: $fontHeight + 10;
		margin: 0 auto;
		padding: 0 0 15px 0;
		text-align: center;
		max-width: $maxContentWidth;

		.quote-author {
			color: $colorGrey;
			display: inline-block;
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			margin: 0 0 -10px 0;
			padding: 0 15px;
			position: relative;
			top: -10px;
			z-index: 1;

			&:before {
				background: $colorGreyLight;
				content: "";
				display: block;
				height: 1px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 10px;
				width: 10px;
				z-index: 1;
			}
		}
	}

	.button {
		display: none;
	}

	.video-link {
		display: none;
	}

	ul {
		text-align: left;
	}
}


// layout
.l-page {
	margin: 0 auto;
	max-width: 960px;

	&__inner {
		margin: 0;
		padding: 20px 0 0;
	}
}

.l-header {
	border-bottom: 1px solid $colorBase;
	margin: 0;
	padding: 0 0 15px 0;
	text-align: center;

	&__logo {
		margin: 0 auto;
		padding: 0;
	}
}

.l-nav {
	border-bottom: 1px solid $colorBlack;
	margin: 0;
	padding: 5px 0 15px 0;
	text-align: center;

	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;

		&__item {
			display: none;
			list-style: none;
			margin: 0;
			padding: 0;

			&--enquire {
				display: block;
				float: right;
				font-size: 0;
				line-height: 0;

				&__phone {
					font-size: $fontSize;
					font-weight: bold;
					line-height: $fontHeight;
				}
			}
		}
	}
}

.l-footer {
	border-top: 1px solid $colorBase;
	margin: 0;
	padding: 0 0 10px 0;

	&__menu {
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -10px 0 0 -10px;
		padding: 15px 0 0 0;

		&__item {
			@include box-sizing();
			color: $colorGrey;
			display: inline-block;
			font-size: $fontSize - 3;
			line-height: $fontHeight - 3;
			list-style: none;
			margin: 0;
			padding: 10px 0 0 25px;
			position: relative;
			vertical-align: top;
			z-index: 1;

			&:before {
				content: "✔";
				left: 10px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 10px;
				z-index: 1;
			}

			a {
				color: $colorGrey;
			}
		}
	}

	&__copyright {
		color: $colorGrey;
		font-size: $fontSize - 3;
		line-height: $fontHeight - 3;
		margin: 0;
		padding: 15px 0 0 0;

		a {
			color: $colorGrey;
		}
	}
}

// print grid
.print-grid {
	font-size: 0;
	line-height: 0;
	margin: -15px 0 0 -15px;
	padding: 0 0 15px 0;
	text-align: left;

	&__col {
		@include box-sizing();
		display: inline-block;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 15px 0 15px 15px;
		vertical-align: top;
		width: 100%;

		&--2 {
			width: 50%;
		}

		&--3 {
			width: 33.3333%;
		}

		&--4 {
			width: 25%;
		}

		&--5 {
			width: 20%;
		}
	}
}

// section
.m-section {
	//border-top: 1px solid $colorDivider;
	margin: 0;
	padding: 15px 0 0 0;
	text-align: center;

	&__title {
		margin: 0;
		padding: 0 0 15px 0;
	}
}

// properties
.m-featured-property {
	margin: 0;
	padding: 0;
	text-align: left;

	&__image {
		margin: 0;
		padding: 0;

		&__image {
			margin: 0;
			padding: 0;
		}
	}

	&__title {
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		margin: 0;
		padding: 10px 0 0 0;
	}

	&__location {
		color: $colorGrey;
		font-size: $fontSize - 1;
		line-height: $fontSize - 1;
		margin: 0;
		padding: 5px 0 0 0;
	}

	&__rooms {
		color: $colorGrey;
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: 0 0 0 -15px;
		padding: 5px 0 0 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize - 1;
			line-height: $fontSize - 1;
			list-style: none;
			margin: 0;
			padding: 0 0 0 15px;
			vertical-align: top;
		}
	}

	&__price {
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 10px 0 0 0;
	}
}

.m-property__header__collection {
	margin: 10px 0;
	max-width: 100px;
}

//tabs cms images
.m-tabs__content .cms img {
	margin: 10px 0;
}

//tabs
.m-tabs__tab {
	display: block !important;
	padding: 10px 0;

	figure {
		display: none;
	}
}

.m-info-block__menu {
	max-width: 400px;

	&__title,
	&__data {
		display: inline-block;
		width: 48%;
		padding: 5px 0;
	}
}

.m-grading__menu__item {
	p {
		display: inline-block;
		padding-right: 3px;
	}
}

.m-phrases__table {
	&__item {
		padding: 5px 25px 5px 0;
	}
}

//booking widget
.m-property__availability__booking-widget__table {
	border-collapse: collapse;
	border-top: 1px solid $colorBorder;
	border-left: 1px solid $colorBorder;
	margin: 20px 0;
	padding: 0;

	td,
	th {
		border-bottom: 1px solid $colorBorder;
		border-right: 1px solid $colorBorder;
		color: $colorBase;
		min-width: 100px;

		div {
			padding: 5px;

			&.selected {
				background: $colorGreyLight;

				label {
					font-weight: 600;
				}
			}
		}

		span {
			font-weight: 600;
			display: inline-block;
			padding-right: 3px;
		}
	}

	th {
		padding: 5px;
	}

	td {
		input {
			display: none;
		}
	}
}

//contact
.m-contact__details__address {
	font-style: normal;
	font-weight: 600;
	line-height: $fontHeight + 10;
	padding: 20px 0;
}

//serach results
.m-search-page {
	&__results {
		padding-top: 15px;
		border-top: 1px solid $colorBorder;
	}

	&__results-item {
		border-bottom: 1px solid $colorBorder;
		margin-bottom: 20px;
	}

	&__results-item__image__image {
		margin: 10px 0;
		max-width: 500px;
	}
}

//collections
.m-collection-summary {
	border-bottom: 1px solid $colorBorder;
	margin: 0 0 25px;

	&__image__image {
		margin: 10px 0;
		max-width: 500px;
	}
}

//destinations
.m-destinations-list {
	&__col {
		display: inline-block;
		vertical-align: top;
		width: 24%;
	}
}

//country
.m-section__links__menu {
	margin: 0;
	text-align: left;
}

// image grid
.m-image-grid {
	&__item {
		margin: 0;
		padding: 0;
		text-align: left;

		&__image {
			margin: 0;
			padding: 0;

			&__image {
				margin: 0;
				padding: 0;
			}
		}

		&__title {
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 10px 0 0 0;
		}
	}
}

// press
.m-press {
	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;
		transform: none !important;
		-webkit-transform: none !important;
		width: auto !important;

		&__item {
			display: none !important;
			float: none !important;
			list-style: none;
			margin: 0;
			padding: 0;
			position: static !important;
			width: auto !important;

			&:first-child {
				display: block !important;
			}
		}

		&__quote {
			font-family: $fontSerif;
			font-size: $fontSize + 10;
			line-height: $fontHeight + 10;
			margin: 0 auto;
			padding: 5px 0 15px;
			text-align: center;
			max-width: $maxContentWidth;

			&__author {
				color: $colorGrey;
				display: inline-block;
				font-size: $fontSize + 6;
				line-height: $fontHeight + 6;
				margin: 0;
				padding: 0 15px;
				position: relative;
				top: 0;
				z-index: 1;

				&:before {
					background: $colorGreyLight;
					content: "";
					display: block;
					height: 1px;
					left: 0;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 10px;
					width: 10px;
					z-index: 1;
				}
			}
		}
	}
}

// marketing
.m-marketing {
	&__menu {
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -15px 0 0 -15px;
		padding: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			list-style: none;
			margin: 0;
			padding: 15px 0 15px 15px;
			text-align: left;
			vertical-align: top;
			width: 33.3333%;

			&--featured,
			&:nth-child(2) {
				width: 50%;
			}

			&--simple {
				width: 25% !important;
			}
		}

		&__image {
			margin: 0;
			padding: 0;

			&__image {
				margin: 0;
				padding: 0;
			}
		}

		&__title {
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 10px 0 0 0;
		}
	}
}

// features
.m-feature {
	border: 1px solid $colorBorder;
	margin: 0;
	padding: 15px;

	&__title {
		font-size: $fontSize;
		font-weight: normal;
		line-height: $fontHeight;
		margin: 0;
		padding: 0 0 15px 0;
	}

	&__image {
		margin: 0;
		padding: 0;

		&__image {
			margin: 0;
			padding: 0;
		}
	}
}

// blog snippets
.m-blog-snippets {
	&__menu {
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -15px 0 0 -15px;
		padding: 0;
		width: auto !important;

		&__item {
			@include box-sizing();
			display: inline-block;
			float: none !important;
			list-style: none;
			margin: 0 !important;
			padding: 15px 0 15px 15px;
			text-align: left;
			vertical-align: top;
			width: 33.3333% !important;
		}

		&__image {
			margin: 0;
			padding: 0;

			&__image {
				margin: 0;
				padding: 0;
			}
		}

		&__title {
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			margin: 0;
			padding: 10px 0 0 0;
		}

		&__info {
			color: $colorGrey;
			font-size: 0;
			line-height: 0;
			list-style: none;
			margin: 0 0 0 -15px;
			padding: 5px 0 0 0;

			&__item {
				@include box-sizing();
				display: inline-block;
				font-size: $fontSize - 1;
				line-height: $fontSize - 1;
				list-style: none;
				margin: 0;
				padding: 0 0 0 15px;
				vertical-align: top;
			}
		}

		&__summary {
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 10px 0 0 0;
		}
	}
}

// explore
.m-explore {
	margin: 0;
	padding: 0 0 15px 0;

	&__menu {
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -15px 0 0 -15px;
		padding: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 15px 0 0 15px;
			text-align: left;
			vertical-align: top;
		}

		&__link {
			border: 1px solid $colorBorder;
			display: block;
			margin: 0;
			padding: 10px 15px;
		}
	}
}

//datepicker
.ui-datepicker {
	display: none;
}

// fixes
@import "base/fixes";
