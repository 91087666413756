@import "base/config";

// colours
$colorBase: #58595b;
$colorOffWhite: #f8f9fb;
$colorRed: #dd002f;
$colorGreen: #a2c486;
$colorYellow: #e0e258;

// 50 shades
$colorGrey: #939598;
$colorGreyMid: lighten($colorGrey, 10%);
$colorGreyLight: lighten($colorGrey, 20%);
$colorDivider: #d3d9e1;
$colorBorder: #cecece;

// simpson
$colorTeal: #72acb3;
$colorBlue: #8bafbb;
$colorBlueLight: #b9d6dc;
$colorPurple: #697d96;
$colorPurpleLight: #8896ab;
$colorPurplePale: #e7ecf2;
$colorAction: #0C3465;
$colorBrightGrey: #58595b;

$colorCollectionSecrets: #682b4c;
$colorCollectionHotels: #936492;
$colorCollectionCreche: #6993cd;
$colorCollectionExclusive: #5c7e97;

$colorCollectionTangaroa: #0d2139;
$colorCollectionTurquoise: #4fb3c4;
$colorCollectionCastro: #b6dcd0;

// links
$colorLink: $colorTeal;
$colorLinkHover: $colorTeal;

// fonts
$fontSimple: Helvetica, Arial, Verdana, sans-serif;
$fontBase: 'FuturaBT-Book', $fontSimple;
$fontBaseMedium: 'FuturaBT-Medium', $fontBase;
$fontBaseBold: 'FuturaBT-Bold', $fontBase;
$fontBaseLight: 'FuturaBT-Light', $fontBase;
$fontSerif: 'mrseavita', Garamond, Georgia, "Times New Roman", serif;
$fontSerifAlt: 'mrseavesitaliclining', $fontSerif;
$fontHeader: 'Didot-Regular';
$fontAwesome: "Font Awesome 6 Pro";
$fontSize: 18px;
$fontHeight: 25px;

// responsive breakpoints
$maxContentWidth: 800px;
$maxPageWidth: 960px;
$maxPageWidthLarge: 1248px;
$bpMobileSmall: 340px; // for 320px
$bpMobileMid: 380px;
$bpMobileLarge: 500px; // for 480px
$bpTablet: 620px; // for 600px
$bpTabletMid: 704px; // for 684px
$bpTabletLarge: 788px; // for 768px
$bpDesktop: 980px; // for 960px;
$bpDesktopClear: 1000px; // for 980px;
$bpDesktopMid: 1160px;
$bpMedium: 1220px;
$bpLarge: 1420px;
$bpExLarge: 1949px;

$navMobileWidth: 375px;
$minHeight: 600px;

// info
$infoBackgroundColor: $colorPurplePale;
$infoFontColor: #606162;
$infoHighlightBackgroundColor: #6D7580;
$infoHighlightFontColor: $colorWhite;

// helpers
$helperColorHighlight: $colorRed;

// Booking widget
$bookingWidgetAlternativePlaneColor: #757575;

// GIF Loader
$svgLoader: '/images/common/my-loader.svg';

// mixins
@mixin image-hover() {
	.no-touchevents & {
		@include transition(all .5s ease);
	}
}

@mixin image-hover-anim() {
	.no-touchevents & {
		@include transform(scale(1.1));
		-webkit-filter: brightness(70%);
		transition: all 1s ease;

	}
}




/* @license
 * MyFonts Webfont Build ID 2658004, 2013-10-04T10:16:07-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Futura Light by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/light/
 * 
 * Webfont: Futura Light Italic by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/light-italic/
 * 
 * Webfont: Futura Book by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/book/
 * 
 * Webfont: Futura Medium by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/medium/
 * 
 * Webfont: Futura Bold by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/bold/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2658004
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 * 
 *  2013 MyFonts Inc
*/

@import url(https://hello.myfonts.net/count/288ed4);

@font-face {
	font-family: 'FuturaBT-Light';
	font-display: swap;
	src: url('/fonts/288ED4_0_0.woff') format('woff');
}

@font-face {
	font-family: 'FuturaBT-LightItalic';
	font-display: swap;
	src: url('/fonts/288ED4_1_0.woff') format('woff');
}

@font-face {
	font-family: 'FuturaBT-Book';
	font-display: swap;
	src: url('/fonts/288ED4_2_0.woff') format('woff');
}

@font-face {
	font-family: 'FuturaBT-Medium';
	font-display: swap;
	src: url('/fonts/288ED4_3_0.woff') format('woff');
}

@font-face {
	font-family: 'FuturaBT-Bold';
	font-display: swap;
	src: url('/fonts/288ED4_4_0.woff') format('woff');
}

@font-face {
	font-family: 'Didot-Regular';
	font-display: swap;
	src: url('/fonts/Didot Regular.woff') format('woff');
}

@font-face {
	font-family: 'Didot-Italic';
	font-display: swap;
	src: url('/fonts/Didot Italic.woff') format('woff');
}

/*
* Emigre web font kit 56741bca28701
*
* These fonts are not shareware.
* Use of these fonts requires the purchase
* of a license from Emigre, www.emigre.com
* Please contact sales@emigre.com
*
* To purchase a license to use this font go to:
* http://www.emigre.com/WebFonts/Mrs-Eaves/Italic
*
*  2015 Emigre, Inc
*/

@font-face {
	font-family: 'mrseavita';
	src: url('/fonts/mrseavita-020415006EmigreWebOnly.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'mrseavesitaliclining';
	src: url('/fonts/mrseavesitaliclining-020415006EmigreWebOnly.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
